// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.vtex-render-runtime-8-x-imgHeight {\n  height: 80px;\n}\n\n.vtex-render-runtime-8-x-errorStack {\n  margin: 20px auto;\n  max-width: 980px;\n  word-wrap: 'break-word';\n}\n\n.vtex-render-runtime-8-x-errorDetails {\n  font-family: 'courier, code';\n  max-width: 980px;\n  margin: 20px auto;\n  word-wrap: 'break-word';\n}\n\n.vtex-render-runtime-8-x-stringValue {\n  word-wrap: 'break-all';\n}\n\n@media only screen and (min-width: 40em) {\n  .vtex-render-runtime-8-x-imgHeight {\n    height: 225px;\n  }\n}\n", "",{"version":3,"sources":["webpack://./react/error.css"],"names":[],"mappings":";AACA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,4BAA4B;EAC5B,gBAAgB;EAChB,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":["\n.imgHeight {\n  height: 80px;\n}\n\n.errorStack {\n  margin: 20px auto;\n  max-width: 980px;\n  word-wrap: 'break-word';\n}\n\n.errorDetails {\n  font-family: 'courier, code';\n  max-width: 980px;\n  margin: 20px auto;\n  word-wrap: 'break-word';\n}\n\n.stringValue {\n  word-wrap: 'break-all';\n}\n\n@media only screen and (min-width: 40em) {\n  .imgHeight {\n    height: 225px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imgHeight": "vtex-render-runtime-8-x-imgHeight",
	"errorStack": "vtex-render-runtime-8-x-errorStack",
	"errorDetails": "vtex-render-runtime-8-x-errorDetails",
	"stringValue": "vtex-render-runtime-8-x-stringValue"
};
module.exports = ___CSS_LOADER_EXPORT___;
